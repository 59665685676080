






















































import Vue from 'vue'
import { Component } from "vue-property-decorator";
import isMobile from "@/assets/utils/isMobile";

@Component({
  data() {
    return {
      isMobile
    }
  }
})
export default class EditorButtonsHelpView extends Vue{
}
