var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ranks-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"tags":_vm.tariffTag},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.tariffTag)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(!_vm.tariffTag)?[_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'rank_action_message',
            'placeholders': _vm.RANK_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'hasMedia': true
          },
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'allow_to_reduce_rank',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'user_ranks_by_xp',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'user_ranks_by_reputation',
            'hasAnchor': true,
          }
        }}}),_c('scoring-system',{staticClass:"mt-2",attrs:{"use-reputation":_vm.module.model.params.user_ranks_by_reputation,"use-xp":_vm.module.model.params.user_ranks_by_xp,"ranks":_vm.frontendRanks},on:{"input":_vm.onRanksChange}})],1)]:_c('not-available-options-overlay',{attrs:{"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.tariffTag}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }